import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrimaryBtnModule } from '@common/modules/UI/primary-btn/primary-btn.module';
import { DateRangerModule } from '@common/modules/dateranger/date-ranger.module';
import { HeaderModule } from '@common/modules/header/header.module';
import { CoreModule } from '@core/core.module';
import { WINDOW_PROVIDERS } from '@core/window/window.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_INITIALIZER_PROVIDERS } from './app.init';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HeaderModule,
    BrowserAnimationsModule,
    PrimaryBtnModule,
    DateRangerModule,
  ],
  providers: [WINDOW_PROVIDERS, APP_INITIALIZER_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
