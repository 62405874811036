import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IBaseData, IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { Observable } from 'rxjs';

export interface IOffice extends IBaseData {
  address: string;
}

@Injectable({ providedIn: 'root' })
export class OfficeApiService {
  constructor(private http: HttpClient) {}

  public saveOffice(office: IOffice): Observable<any> {
    return this.http.post('office', office);
  }

  public getOfficesList(params?: IParamsRequestKeys): Observable<IRes<IOffice>> {
    return this.http.get<IRes<IOffice>>('offices', { params });
  }

  public getOffice(id: number): Observable<IOffice> {
    return this.http.get<IOffice>(`office/${id}`);
  }

  public updateOffice(id: number, office: IOffice): Observable<any> {
    return this.http.put(`office/${id}`, office);
  }

  public deleteOffice(id: number): Observable<IOffice> {
    return this.http.delete<IOffice>(`office/${id}`);
  }
}
