import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiNotificationService } from '@core/services/api-notification.service';
import { ViewContainerRefService } from '@core/services/view-container-ref.service';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OnSaveInterceptor implements HttpInterceptor {
  private requestCounter = 0;

  constructor(
    private apiNotificationService: ApiNotificationService,
    private viewContainerRefService: ViewContainerRefService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCounter++;
    const hideNotification = req.headers.get('X-Hide-Notification');
    return next.handle(req).pipe(
      filter(event => event instanceof HttpResponse),
      tap(event => {
        if (!hideNotification && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(req.method)) {
          const viewContainerRef = this.viewContainerRefService.getViewContainerRef();
          if (viewContainerRef) {
            this.apiNotificationService.showNotification(
              viewContainerRef,
              req.method === 'DELETE' ? 'Deleted successfully' : null
            );
          }
        }
        this.requestCounter--;
      })
    );
  }
}
