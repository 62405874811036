import { Injectable } from '@angular/core';
import { NotificationsService } from '@common/modules/header/services/notifications.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

@Injectable({ providedIn: 'root' })
export class SocketService {
  public host!: string;
  private socket: any;
  public token!: string;

  constructor(private notificationsService: NotificationsService) {
    // this.socket = io.connect(environment.host, { secure: true, auth: { token: 'sfg' } });
    // console.log('token', this.token);
  }

  public connect(): void {
    this.socket = io.connect(environment.host, { secure: true, auth: { token: this.token } });

    this.socket.on('connect', () => {
      console.log('a user connected');

      this.socket.on('disconnect', () => {
        console.log('user disconnected');
      });

      this._subscribeToNotifications();
    });
  }

  public subscribeToEvent(event: string, callback: (data: any) => void): void {
    this.socket.on(event, callback);
  }

  public subscribeToEvent1(event: string): Observable<any> {
    return this.socket.on(event);
  }

  public unsubscribeFromEvent(event: string): void {
    this.socket.off(event);
  }

  private _subscribeToNotifications(): void {
    this.socket.on('proposal:create', (data: any) => {
      this.notificationsService.updateData();
    });
  }
}
