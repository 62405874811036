import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalUtilityService {
  constructor(private http: HttpClient) {}

  public getProposalTypes(): Observable<any> {
    return this.http.get<any>('request-for-proposal-types');
  }

  public getProposalStatuses(): Observable<any> {
    return this.http.get<any>('request-for-proposal-statuses');
  }

  public setProposalStatus(id: number, status: string): Observable<any> {
    return this.http.put<any>(`rfp-status/${id}`, { status });
  }

  public setProposalType(id: number, type: string): Observable<any> {
    return this.http.put<any>(`rfp-type/${id}`, { type });
  }
}
