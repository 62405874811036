<div class="date-ranger-container">
  <input
    type="text"
    matInput
    ngxDaterangepickerMd
    [formControl]="dateRangerControl"
    [showCustomRangeLabel]="showCustomRangeLabel"
    [alwaysShowCalendars]="alwaysShowCalendars"
    [ranges]="ranges"
    [opens]="'right'"
    [linkedCalendars]="true"
    [showClearButton]="false"
    [closeOnAutoApply]="true"
    [placeholder]="'Set date range'"
    [customRangeDirection]="true"
    [startKey]="startKey"
    [endKey]="endKey"
    [autoApply]="true"
    [lockStartDate]="lockStartDate"
    [timePicker24Hour]="true"
    [isInvalidDate]="isInvalidDate"
    [minDate]="minDate"
    [locale]="locale"
    [maxDate]="maxDate"
    [showWeekNumbers]="showWeekNumbers"
    [showDropdowns]="true"
    [dateLimit]="dateLimit!" />

  <div class="separator" *ngIf="separator">
    <span>{{ separator }}</span>
  </div>

  <div class="icon">
    <span id="calendar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.74"
        height="19.165"
        viewBox="0 0 18.74 19.165">
        <defs>
          <style>
            .a {
              opacity: 0.403;
            }
            .b {
              fill: #343434;
            }
          </style>
        </defs>
        <g class="a" transform="translate(-5.411)">
          <path
            class="b"
            d="M119.658,258.719a.478.478,0,0,0-.478-.477h-1.668a.478.478,0,0,0-.478.477v1.667a.478.478,0,0,0,.478.478h1.668a.478.478,0,0,0,.478-.478v-1.667Z"
            transform="translate(-107.305 -248.246)" />
          <path
            class="b"
            d="M216.293,258.719a.478.478,0,0,0-.477-.477h-1.668a.478.478,0,0,0-.478.477v1.667a.478.478,0,0,0,.478.478h1.668a.478.478,0,0,0,.477-.478Z"
            transform="translate(-200.201 -248.246)" />
          <path
            class="b"
            d="M312.921,258.719a.478.478,0,0,0-.478-.477h-1.668a.478.478,0,0,0-.478.477v1.667a.478.478,0,0,0,.478.478h1.668a.478.478,0,0,0,.478-.478Z"
            transform="translate(-293.09 -248.246)" />
          <path
            class="b"
            d="M119.658,355.353a.478.478,0,0,0-.478-.478h-1.668a.477.477,0,0,0-.478.478v1.667a.478.478,0,0,0,.478.478h1.668a.478.478,0,0,0,.478-.478v-1.667Z"
            transform="translate(-107.305 -341.459)" />
          <path
            class="b"
            d="M216.293,355.353a.477.477,0,0,0-.477-.478h-1.668a.477.477,0,0,0-.478.478v1.667a.478.478,0,0,0,.478.478h1.668a.478.478,0,0,0,.477-.478Z"
            transform="translate(-200.201 -341.459)" />
          <path
            class="b"
            d="M312.921,355.353a.477.477,0,0,0-.477-.478h-1.668a.477.477,0,0,0-.478.478v1.667a.478.478,0,0,0,.478.478h1.668a.478.478,0,0,0,.477-.478v-1.667Z"
            transform="translate(-293.09 -341.459)" />
          <path
            class="b"
            d="M22.483,54.15v2.29a1.882,1.882,0,0,1-1.9,1.869h-1.2a1.892,1.892,0,0,1-1.909-1.869v-2.3h-5.4v2.3a1.892,1.892,0,0,1-1.909,1.869h-1.2a1.882,1.882,0,0,1-1.9-1.869V54.15a1.714,1.714,0,0,0-1.668,1.7V69.265a1.716,1.716,0,0,0,1.718,1.707h15.3a1.718,1.718,0,0,0,1.718-1.707V55.846A1.714,1.714,0,0,0,22.483,54.15Zm-.556,14.279a.738.738,0,0,1-.743.734H8.345a.738.738,0,0,1-.743-.734V61.5a.738.738,0,0,1,.743-.734H21.184a.738.738,0,0,1,.743.734v6.931Z"
            transform="translate(0 -51.807)" />
          <path
            class="b"
            d="M80.265,5.6h1.3a.715.715,0,0,0,.715-.715V.715A.715.715,0,0,0,81.566,0h-1.3a.715.715,0,0,0-.715.715V4.89A.715.715,0,0,0,80.265,5.6Z"
            transform="translate(-71.272 0)" />
          <path
            class="b"
            d="M345.173,5.6h1.3a.715.715,0,0,0,.715-.715V.715A.715.715,0,0,0,346.474,0h-1.3a.715.715,0,0,0-.715.715V4.89A.715.715,0,0,0,345.173,5.6Z"
            transform="translate(-325.937 0)" />
        </g>
      </svg>
    </span>
  </div>
</div>
