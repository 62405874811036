export const DAYS_OF_WEEK = ['Su', 'Mo', 'Thue', 'we', 'Thur', 'Fr', 'Sa'];

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Des',
];
