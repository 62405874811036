import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DateRangerComponent } from '@common/modules/dateranger/date-ranger.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [DateRangerComponent],
  exports: [DateRangerComponent],
  imports: [CommonModule, ReactiveFormsModule, NgxDaterangepickerMd.forRoot(), MatInputModule],
})
export class DateRangerModule {}
