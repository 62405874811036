import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { IRanger, IRangerString } from '@common/interfaces/base.interfaces';
import { DAYS_OF_WEEK, MONTH_NAMES } from '@common/modules/dateranger/helper';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/nb';
import { distinct, filter, map, tap } from 'rxjs/operators';

const defaultLocaleConfig = {
  firstDay: 0,
  format: 'MM/DD/YYYY',
};

import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en.js';
import utc from 'dayjs/plugin/utc';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';

@UntilDestroy()
@Component({
  selector: 'app-date-ranger',
  templateUrl: './date-ranger.component.html',
  styleUrls: ['./date-ranger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'openRanger()',
  },
})
export class DateRangerComponent implements OnInit, AfterViewInit {
  @Input() public isFireChangesAtFirstClickOnRange = false;
  @Input() set viewDateStartOnInput(value: boolean) {
    if (this.rangerDirective?.picker) {
      this.rangerDirective.picker.autoUpdateInput = value;
      this._viewDateStartOnInput = value;

      if (!value) {
        this.rangerDirective.writeValue(this._setDefaultRange());
      }
    }
  }
  @Input() public separator = '|';
  @Input() public startDate: Dayjs | string | undefined;
  @Input() public endDate: Dayjs | string | undefined;
  @Input() public startKey = 'startDate';
  @Input() public endKey = 'endDate';
  @Input() public alwaysShowCalendars = true;
  @Input() public outputFormat = 'MM.DD.YYYY';
  @Input() public lockStartDate = false;
  @Input() public showCustomRangeLabel = true;
  @Input() public ranges: any = {};
  @Input() public minDate: dayjs.Dayjs | undefined;
  @Input() public maxDate: dayjs.Dayjs | undefined;
  @Input() public showWeekNumbers = false;
  @Input() public dateLimit: number | null = null;
  @Input() get locale(): LocaleConfig {
    return this._locale;
  }

  @Input() isInvalidDate: (date: dayjs.Dayjs) => boolean = () => false;

  @ViewChild(DaterangepickerDirective, { static: false })
  public rangerDirective!: DaterangepickerDirective;
  @Output() readonly changeRangerEmitter = new EventEmitter<IRangerString>();

  public dateRangerControl = new UntypedFormControl(null);

  private _locale: LocaleConfig = defaultLocaleConfig;
  private _viewDateStartOnInput = false;
  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._initDayjs();
    this._buildRanges();
    this.locale.daysOfWeek = DAYS_OF_WEEK;
    this.locale.monthNames = MONTH_NAMES;
  }

  public ngAfterViewInit(): void {
    const { startKey, endKey, outputFormat } = this;
    this._buildRanges();
    this.dateRangerControl.valueChanges
      .pipe(
        filter(
          (ranger: IRanger) =>
            !!ranger && !!ranger[startKey] && !!ranger[endKey]
        ),
        distinct((ranger: IRanger) => ranger[startKey] && ranger[endKey]),
        map(
          (ranger: IRanger): IRangerString => ({
            [startKey]: ranger[startKey].format(outputFormat),
            [endKey]: ranger[endKey].format(outputFormat),
          })
        ),
        tap(() => !environment.production),
        untilDestroyed(this)
      )
      .subscribe((dateRanges: IRangerString) => {
        this.changeRangerEmitter.emit(dateRanges);
      });

    // this.dateRangerControl.setValue({
    //   [this.startKey]: (this.startDate && !(this.startDate instanceof dayjs) && dayjs(this.startDate)) || this.startDate,
    //   [this.endKey]: (this.endDate && !(this.endDate instanceof dayjs) && dayjs(this.endDate)) || this.endDate
    // });

    this.dateRangerControl;
    if (!this._viewDateStartOnInput) {
      this.rangerDirective.picker.autoUpdateInput = false;
      // this.rangerDirective.writeValue(this._setDefaultRange());
    }

    this.cdr.detectChanges();
  }

  public openRanger(): void {
    if (!this._viewDateStartOnInput) {
      this.rangerDirective.picker.autoUpdateInput = true;
      this.rangerDirective.writeValue(this.dateRangerControl.value);

      if (this.isFireChangesAtFirstClickOnRange) {
        this.rangerDirective.picker.clickApply();
      }
    }

    this.rangerDirective.open();
  }

  private _buildRanges() {
    if (this.ranges === null) {
      this.showCustomRangeLabel = false;
      return;
    }

    this.ranges = {
      Today: [dayjs(), dayjs()],
      Yesterday: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
      'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
      'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
      'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
      'Last Month': [
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
      ],
    };
  }

  private _setDefaultRange(): TimePeriod {
    return { startDate: dayjs(), endDate: dayjs() };
  }

  private _initDayjs() {
    dayjs.extend(utc);
    dayjs.locale('en');
  }
}
