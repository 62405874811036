import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRes } from '@common/interfaces/base-table.interface';
import { IParamsRequestKeys } from '@common/interfaces/base.interfaces';
import { DesignPriceConfig } from '@common/interfaces/price.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PriceConfigService {
  constructor(private http: HttpClient) {}

  public getPriceConfigList(): Observable<any> {
    return this.http.get('price-configs');
  }

  public getDesignPriceConfigList(params?: IParamsRequestKeys): Observable<IRes<DesignPriceConfig>> {
    return this.http.get<IRes<DesignPriceConfig>>(`commercial-ranges`, { params });
  }

  public saveDesignPriceConfig(data: DesignPriceConfig): Observable<DesignPriceConfig> {
    return this.http.post<DesignPriceConfig>(`commercial-ranges`, data);
  }

  public updateDesignPriceConfig(id: number, data: DesignPriceConfig): Observable<DesignPriceConfig> {
    return this.http.put<DesignPriceConfig>(`commercial-ranges/${id}`, data);
  }

  public deleteDesignPriceConfig(id: number): Observable<any> {
    return this.http.delete(`commercial-ranges/${id}`);
  }
}
