import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { selectAuthState } from '@core/store/auth/auth.reducers';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  public canActivate(): Observable<true | UrlTree> {
    return this.store
      .select(selectAuthState)
      .pipe(map(({ isAuth }) => (!isAuth ? true : this.router.createUrlTree(['/']))));
  }

  public canActivateChild(): Observable<true | UrlTree> {
    return this.canActivate();
  }
}
